html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.badgeVideosQuestions {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid #ffffff20;
}

#filterBoxScroll::-webkit-scrollbar {
  display: none;
}

.gradientAvatar img {
  padding: 2px;
  background: linear-gradient(
    66deg,
    orange 0%,
    rgb(255, 205, 111),
    rgb(255, 229, 182) 70%,
    orange 100%
  );
  background-size: 700% 700%;
  animation: gradient-animation 10s infinite;
}

.gradientAvatar::after {
  content: '';
  position: absolute;
  width: 30%;
  height: 30%;
  background-image: url('/public/images/crown-mike.png');
  background-size: contain;
  background-repeat: no-repeat;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
